import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Challenges.module.scss"

const Challenges = ({ strapiData, product, moduleColumn }) => {
  // const image = getImage(strapiData?.secImages[0]?.localFile)

  return (
    <div className={`${styles.Challenges} `}>
      <Container>
        <div className={styles.hire}>
          <h2
            className={`${styles.hireHeading} ${
              product ? styles.productHeading : ""
            }`}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Row className="d-flex gap-30">
            {strapiData?.cards &&
              strapiData?.cards?.map((item, index) => (
                <Col lg={4} className="d-flex gap-30">
                  <div className={styles.text}>
                    <img
                      className={`${styles.tick} ${
                        moduleColumn ? styles.moduleColumnPlay : ""
                      }`}
                      src="https://invozone-backend.s3.us-east-1.amazonaws.com/alert_0931410904.png"
                      alt={item?.title || "alert"}
                      decoding="async"
                      loading="lazy"
                      style={{
                        height: "60px",
                        width: "50px",
                        marginBottom: "50px",
                      }}
                    />
                    <h2>{item?.title}</h2>
                    <p>{item?.subTitle}</p>
                  </div>
                </Col>
              ))}
          </Row>
          <div className={styles.effort}>
            <img
              src="https://invozone-backend.s3.us-east-1.amazonaws.com/challanges_c2905e13d1.png"
              alt="effort"
              decoding="async"
              loading="lazy"
              style={{
                height: "60px",
                width: "60px",
                marginRight: "20px",
              }}
            />
            <p
              className={styles.subTitle}
              dangerouslySetInnerHTML={{
                __html: strapiData?.subTitle2,
              }}
            />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Challenges
