import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Result.module.scss"

const Challenges = ({ strapiData, product, moduleColumn }) => {
  // const image = getImage(strapiData?.secImages[0]?.localFile)

  return (
    <div className={`${styles.Challenges} `}>
      <Container>
        <div className={styles.hire}>
          <h2
            className={`${styles.hireHeading} ${
              product ? styles.productHeading : ""
            }`}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Row className="gap-30">
            {strapiData?.cards &&
              strapiData?.cards?.map((item, index) => (
                <Col lg={6} className="d-flex gap-30">
                  <div className={styles.talent} key={index}>
                    <div className={styles.text}>
                      <img
                        className={`${styles.tick} ${
                          moduleColumn ? styles.moduleColumnPlay : ""
                        }`}
                        src="https://invozone-backend.s3.us-east-1.amazonaws.com/check_ico_c27b81cd78.png"
                        alt={item?.title}
                        decoding="async"
                        loading="lazy"
                        style={{
                          height: "48px",
                          width: "48px",
                        }}
                      />
                      <h2>{item?.title}</h2>
                      <p>{item?.subTitle}</p>
                    </div>
                  </div>
                </Col>
              ))}
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default Challenges
