import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Aim.module.scss"

const Solutions = ({ strapiData }) => {
  return (
    <div className={`${styles.Challenges} `}>
      <Container>
        <div className={styles.hire}>
          <h2
            className={`${styles.hireHeading} `}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />

          <Row className="gap-30">
            {strapiData?.cards &&
              strapiData?.cards?.map((item, i) => (
                <Col lg={6} className="d-flex gap-30">
                  <div className={styles.cards}>
                    <h3>{item?.title}</h3>
                    <p>{item?.subTitle}</p>
                    <div className={styles.text}>
                      <img
                        className={styles.tick}
                        src="https://invozone-backend.s3.us-east-1.amazonaws.com/target_1_3cfdb3d345.png"
                        alt={item?.title}
                        decoding="async"
                        loading="lazy"
                        style={{ height: "48px", width: "48px" }}
                      />
                      <p>{item?.subTitle2}</p>
                    </div>
                  </div>
                </Col>
              ))}
          </Row>
          <div className={styles.collaborated}>
            <span></span>
            <div
              className={styles.subTitle2}
              dangerouslySetInnerHTML={{
                __html: strapiData?.subTitle2,
              }}
            />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Solutions
